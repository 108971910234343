::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

label {
    clear: both;
    float: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 10px;
    display: block;
    font-family: var(--primary-font-family);
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    text-align: left;
}

.Reservation-Input,
.Contact-Input,
.Reservation-Comment {
    box-sizing: border-box;
    font-family: var(--primary-font-family);
    font-size: 100%;
    width: 100%;
    outline: none;
}

.Reservation-Input {
    padding: 13px 10px;
    background: 0 0 !important;
    border: 0;
    border-bottom: 1px solid var(--bs-white);
    color: var(--bs-white);
    border-radius: 0;
    outline: none;

    &:focus {
        border-bottom: 1px solid var(--primary-base-color);
    }

    &:focus ~ label {
        color: var(--primary-base-color);
    }
}

.Reservation-Select {
    background: var(--primary-base-color);
}

.Contact-Input {
    color: #444;
    padding: 10px 10px;
    margin: 0 0 10px 0;
    border: 1px solid #e1e1e1;
}

select {
    margin: 0;
    padding: 13px 10px;
    color: var(--bs-white);
    background: 0 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--bs-white);
    width: 100%;
    font-size: 100%;
    font-family: var(--primary-font-family);
    outline: none;
    -webkit-transition: border-color linear .3s;
    -moz-transition: border-color linear .3s;
    -o-transition: border-color linear .3s;
    transition: border-color linear .3s;
    box-sizing: border-box;
    -webkit-appearance: none;
}
