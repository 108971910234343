@import '../abstract/media';

html,
body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: var(--primary-font-family);
    @include box-sizing(border-box);
}

blockquote {
    margin: 2em auto 0 auto;
    padding: 0 5% 0 5%;
    text-align: center;
    max-width: 50em;

    div {
        margin-bottom: 25px;
    }

    .Blockquote-Content {
        line-height: 1.7;
        font-weight: 500;
        font-size: 30px;
        text-transform: uppercase;
        opacity: 0;
    }

    .Blockquote-Description {
        letter-spacing: .3em;
        font-size: .7em;
        text-transform: uppercase;
        opacity: 0;
    }

    .Blockquote-Author {
        font-size: 40px;
        font-weight: 600;
        font-family: var(--secondary-font-family);
        opacity: 0;
    }
}

button:hover {
    cursor: pointer;
}

h2 {
    margin: 0;
    padding: 0;
    font-size: 4.4rem;
    line-height: 0;

    @include media(mobile, tablet) {
        line-height: unset;
    }

    @include mobile {
        font-size: 2em;
    }

    @include tablet {
        font-size: 3.5em;
    }

    .Subtitle {
        font-family: var(--secondary-font-family);
        color: var(--primary-base-color);
    }

    .Headline {
        font-weight: 100;
        text-transform: uppercase;
        color: var(--bs-white);

        strong {
            font-weight: 800;
        }

        @include mobile {
            font-size: 1.5em;

            strong {
                display: block;
            }
        }
    }

    .Headline,
    .Subtitle {
        @include media(mobile, tablet) {
            margin: 0;
        }
    }

    .Headline-Strong {
        font-weight: 800;
        text-transform: uppercase;
        color: var(--bs-black);
    }
}

h3 {
    color: var(--bs-white);
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0;
    margin: 0;
    line-height: 1;
}

h4 {
    text-align: center;
    font-weight: 800;
    font-size: 2.6em;
}

.Main-Section {
    height: 100vh;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(0, 0, 0, .3);
    }
}

.Leaf-Delimiter {
    height: 1.5rem;
    width: 1.5rem;
    object-fit: contain;
    opacity: .3;
    margin: .8rem 0;
}

.Elementor {
    position: absolute;
    right: 0;
    z-index: 1111;
    width: 100%;
    height: 20px;

    &-Top {
        top: 0;
    }

    &-Bottom {
        transform: rotate(180deg);
        bottom: 0;
    }
}

.Simple-Text {
    color: var(--primary-light-color);
    font-size: 1.2em;
    line-height: 1.8;
    font-weight: 300;
    word-wrap: break-word;
}
