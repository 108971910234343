@-webkit-keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

@-moz-keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

@-o-keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

@keyframes text-rotate {
    0% {
        transform: rotateX(45deg) translateY(-30px);
        opacity: 0;
    }

    100% {
        transform: rotateX(0) translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes text-rotate {
    0% {
        transform: rotateX(45deg) translateY(-30px);
        opacity: 0;
    }

    100% {
        transform: rotateX(0) translateY(0);
        opacity: 1;
    }
}

@-o-keyframes text-rotate {
    0% {
        transform: rotateX(45deg) translateY(-30px);
        opacity: 0;
    }

    100% {
        transform: rotateX(0) translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@-webkit-keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@-o-keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@-moz-keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: .6;
    }

    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@-webkit-keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: .6;
    }

    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@-o-keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: .6;
    }

    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: .6;
    }

    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@-moz-keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: .6;
    }

    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }

    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@-webkit-keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: .6;
    }

    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }

    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@-o-keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: .6;
    }

    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }

    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: .6;
    }

    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }

    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@include fadeIn-animation-generation;
