.Button {
    background: transparent;
    border: transparent;

    &-Product-Edit {
        margin: auto 0;
        display: inline-block;
        padding: 12px 24px;
        text-align: center;
        max-width: 150px;
        font-size: 15px;
        font-weight: 500;
        text-transform: capitalize;
        letter-spacing: 0;
        border-style: solid;
        color: #fff;
        border-width: 2px 2px 2px 2px;
        border-color: var(--bs-white);
        border-radius: 5px 5px 5px 5px;
        transition: all 300ms;
        border: 2px solid transparent;
    }

    &-Edit-Success {
        background: var(--notification-success);

        &:hover {
            color: var(--notification-success);
            background: transparent !important;
            border: 2px solid var(--notification-success) !important;
        }
    }

    &-Edit-Cancel {
        background: var(--notification-alert);

        &:hover {
            color: var(--notification-alert);
            background: transparent !important;
            border: 2px solid var(--notification-alert) !important;
        }
    }

    &-Light {
        color: var(--bs-white);
    }

    &-Filled {
        color: var(--bs-white);
        background: var(--primary-base-color) !important;
    }

    &-Submit {
        background: var(--notification-success) !important;
    }

    &-Cancel {
        background: var(--notification-alert) !important;
    }

    &-Close,
    &-Close-Reservation {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    &-Close {
        font-size: 1.1rem;
    }

    &-Close-Reservation {
        font-size: 1.3rem;
    }

    &-Language-Switcher {
        .Current-Language {
            margin-left: .5rem;
            text-transform: uppercase;
        }
    }

    &-Language-Option {
        display: block;
        font-size: 1rem;
        color: var(--bs-black);
        padding: 10px 0;

        &:hover {
            color: var(--primary-base-color);
        }
    }

    &-Reservation {
        margin: auto 0;
        justify-self: end;
        display: inline-block;
        line-height: 1;
        padding: 12px 24px;
        text-align: center;
        max-width: 150px;
        font-size: 15px;
        font-weight: 900;
        text-transform: capitalize;
        letter-spacing: 0;
        background-color: #02010100;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: var(--bs-white);
        border-radius: 5px 5px 5px 5px;
        transition: all 300ms;

        &:hover {
            transition: all 300ms;
            color: var(--bs-white) !important;
            background: var(--primary-base-color) !important;
            border-color: var(--primary-base-color) !important;
        }
    }

    &-Scroll-Top {
        background: #eee;
        text-align: center;
        padding: 15px;
        position: fixed;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
        transition: color .3s, background-color .3s, opacity .3s, visibility .3s;
        -moz-transition: color .3s, background-color .3s, opacity .3s, visibility .3s;
        -o-transition: color .3s, background-color .3s, opacity .3s, visibility .3s;
        -webkit-transition: color .3s, background-color .3s, opacity .3s, visibility .3s;
        z-index: 9999;
        opacity: .5;
        visibility: hidden;
        display: block;
        border-radius: 5px;
        border: 1px solid transparent;
        box-shadow: 5px 5px 15px rgb(0 0 0 / 30%);

        &:hover {
            opacity: 1;
        }
    }

    &-Policy {
        max-width: unset;
        width: unset;
    }

    &-GetAndroidApp-Close {
        margin-right: 10px;
        font-size: 18px;
        color: #333;
    }
}

button:disabled,
button[disabled] {
    opacity: .5;
}
