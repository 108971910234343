::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    background: var(--primary-base-color);
}
