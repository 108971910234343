.List {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &-Footer {
        display: flex;
        justify-content: flex-end;

        @include mobile {
            justify-content: center;
            align-items: center;
            margin-top: 15px;
        }

        @include tablet {
            align-items: center;
            height: 100%;
        }

        &-Item {
            padding: 0 8px;
            text-transform: capitalize;
        }
    }

    &-Elementor,
    &-Light {
        display: flex;
        flex-direction: column;
        margin: 20px 0 0 0;
    }

    &-Elementor-Item,
    &-Light-Item {
        font-size: 1rem;
        margin-top: calc(15px / 2);
        padding-bottom: calc(15px / 2);
    }
}
